import React, { useState } from "react";
import "./App.css";
import "./input.css";
import Navbar from "./components/Navbar/Navbar.js";
import ContactModal from "./components/ContactModal/ContactModal.js";
import Welcome from "./components/Welcome/Welcome";
import About from "./components/About/About.js";
import ProjectsGrid from "./components/ProjectsGrid/ProjectsGrid.js";
import GameProjects from "./components/GameProjects/GameProjects";
// import GameProjectShowcase from "./components/GameProjectShowcase/GameProjectShowcase";
import Footer from "./components/Footer/Footer.js";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="w-full p-4"></div>
        <div className="container mx-auto p-4 grid grid-cols-1 gap-4">
          <div id="home" className="col-span-1">
            <Welcome />
          </div>
          {/* <div id="game-showcase" className="col-span-1 mt-8 w-full">
            <div className="bg-gradient-to-r from-green-500 to-teal-600 text-white p-8 rounded-lg shadow-md">
              <h2 className="text-3xl font-bold mb-4 text-center">Game Project Showcase</h2>
              <GameProjectShowcase />
            </div>
          </div> */}
          <div id="work" className="col-span-1 mt-8">
            <ProjectsGrid />
            <GameProjects />
          </div>
          <div id="about" className="col-span-1 mt-8">
            <About />
          </div>
        </div>
      </div>
      <Footer />
      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default App;
