const About = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <section className="mb-10">
        <h1 className="text-4xl font-bold mb-4">About Me</h1>
        <p className="text-lg">
          {/* bio text */}
          My name is Chase and I am a full stack web developer based in the Utah
          Valley. I specialize in creating dynamic and responsive web
          applications using technologies such as <b>React</b>, <b>Node.js</b>,
          <b> Angular</b>, <b>Typescript</b>, <b>C#</b>, and more. I am driven
          by the desire to create user-centric solutions that solve real-world
          problems. My strong foundation in backend databases, such as
          <b> MongoDB </b> and <b>PostgreSQL</b>, allows me to develop
          comprehensive full stack applications. I value continuous learning,
          collaboration, and bringing a project from concept to completion.
          <br></br>
          <br></br>
          In addition to my technical skills, I bring strong problem-solving
          abilities and a detail-oriented work ethic. Outside of coding, I enjoy
          soccer, hockey, photography, and video games. Feel free to explore my
          portfolio to see my work or contact me for collaborations or job
          opportunities!
        </p>
      </section>

      <section className="mb-10">
      <h2 className="text-3xl font-bold mb-4">Professional Work</h2>
      <div>
          {/* This will map through experience and jobs*/}
          <div className="mb-6">
            <h3 className="text-2xl font-semibold">
              Code of the North - <span className="text-xl text-gray-700">Jr. Full Stack Developer / Netsuite Consultant</span>
            </h3>
            <h4 className="mb-1 text-xl font-semibold">January 2023 - Present</h4>
            <p className="text-lg">
            Developed and maintained full stack applications with a focus on backend solutions using Python and TypeScript.
            I consulted on NetSuite implementations, providing expertise in backend development and integration solutions. 
            I collaborated with cross-functional teams to optimize business processes and improve software functionality.
            </p>
          </div>
          {/* Repeat the above div for each experience */}
        </div>
        <h2 className="text-3xl font-bold mb-4">Experience</h2>
        <div>
          {/* This will map through experience and jobs*/}
          <div className="mb-6">
            <h3 className="text-2xl font-semibold">
              2 years experience with Web Development
            </h3>
            <p className="text-lg">
              Have worked on front end, back end and full stack web and mobile
              applications. I have experience with HTML, CSS, Javascript, Node,
              MongoDB, PostgreSQL, React, React Native, Angular, TypeScript, and
              Tailwind.
            </p>
          </div>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold">
              2 years experience with Game Development
            </h3>
            <p className="text-lg">
              Developed indie games by myself from the ground up. I have made roguelites,
              platformers, tower defense games, metroidvanias, and more.
              Experienced with Unity, C#, and Godot
            </p>
          </div>
          {/* Repeat the above div for each experience */}
        </div>
      </section>
    </div>
  );
};

export default About;
